import { STYLE_TO_EFFICIENCY } from '@/lib/constants';
import { useMemo } from 'react';

interface FuelCostsProps {
  carType: string;
  electricityCost: number;
  gasolineCost: number;
  mileagePerYear: number;
  years: number;
  fuelType: 'Electric' | 'Hybrid';
}

export const getCostPerYear = (
  mileage: string | number,
  cost: string | number,
  efficiency: string | number
) => {
  return Math.round((Number(mileage) * Number(cost)) / Number(efficiency));
};

export const useFuelCosts = ({
  carType,
  electricityCost,
  gasolineCost,
  mileagePerYear,
  years,
  fuelType,
}: FuelCostsProps) => {
  const { totalSavings, savingsPerYear, gasCost, altCost } = useMemo(() => {
    const body = carType in STYLE_TO_EFFICIENCY ? carType : 'Sedan';

    const gasC = Math.round(
      getCostPerYear(
        mileagePerYear,
        gasolineCost,
        STYLE_TO_EFFICIENCY[body].gas
      )
    );

    let altC;
    if (fuelType === 'Electric') {
      const electricRate = 100 / STYLE_TO_EFFICIENCY[body].electric;
      altC = getCostPerYear(mileagePerYear, electricityCost, electricRate);
    } else {
      altC = gasC * 0.8;
    }
    altC = Math.round(altC);

    const calculatedSavingsPerYear = gasC - altC;
    const calculatedTotalSavings = (gasC - altC) * Number(years);

    return {
      totalSavings: calculatedTotalSavings,
      savingsPerYear: calculatedSavingsPerYear,
      gasCost: gasC,
      altCost: altC,
    };
  }, [carType, electricityCost, gasolineCost, mileagePerYear, years, fuelType]);

  return { totalSavings, savingsPerYear, gasCost, altCost };
};
