import parseDealer from './parseDealer';
import { parseRange } from './parseRange';
import { parseRawListingPrice, parseRawMileage } from './parseVehicle';
import { IncentiveResponse } from './schema/incentives/types';
import {
  VehicleListingModel,
  VehicleListingType,
} from './schema/inventory/types';

export const parseListingVehicle = (
  rawVehicle: VehicleListingModel
): VehicleListingType => {
  const listing = Array.isArray(rawVehicle.listing)
    ? rawVehicle.listing[0]
    : rawVehicle?.listing;

  let images: string[] = [];
  if (rawVehicle.s3_photos) {
    images = rawVehicle.s3_photos;
  } else if (listing?.Photos?.[0]) {
    images = listing.Photos;
  } else if (listing?.Photo) {
    images = [listing.Photo];
  }

  return {
    vin: rawVehicle.Vin ?? '',
    year: rawVehicle.Year ?? -1,
    make: rawVehicle.Make ?? '',
    model: rawVehicle.Model ?? '',
    trim: rawVehicle.Trim ?? '',
    body: rawVehicle.Body ?? '',
    fuelType: rawVehicle.FuelType || '',
    listingId: listing?.ListingID ?? '',
    stockNumber: listing?.StockNumber || '',
    dealer: parseDealer(rawVehicle),
    dealershipType: rawVehicle.DealershipType || '',
    price:
      listing?.Price !== undefined
        ? parseRawListingPrice(listing.Price)
        : { displayValue: 'Coming soon', value: null },
    mileage: listing ? parseRawMileage(listing) : { value: 0, unit: 'mi' },
    images,
    rangeInfo: parseRange(rawVehicle),
    styleId: rawVehicle.StyleId || undefined,

    batteryInfo: {
      batterySize: rawVehicle?.Battery?.size || 'Coming soon',
      batteryTech: rawVehicle?.Battery?.tech || 'Coming soon',
      batteryWarranty: rawVehicle.Battery?.warranty || null,
    },
    drivetrain: rawVehicle.Drive || '',
    badges: rawVehicle.Badges || [],
    shortListingId: (rawVehicle?.ShortListingID as string) ?? null,
    incentives: (rawVehicle?.incentives as IncentiveResponse) || null,
    condition: rawVehicle.Condition || '',
  };
};
